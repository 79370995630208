import axios from 'axios'

const service = axios.create({
    baseURL: process.env.NODE_ENV==='development'?process.env.VUE_APP_BASE_API:process.env.VUE_APP_BASE_URL,
    // baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 5000  
})

// 不需要token验证的 接口白名单
// const APIWhite = ['users/login']

// 请求拦截 设置统一header
service.interceptors.request.use(
    config =>{
        config.headers.Authorization = 'asdfasdasdfasdasdfasdfasdf';
        if (config.method === 'get') {
            config.params = config.data
        }          
        return config;
    },
    error =>{
        return Promise.reject(error)
    }
)
// 响应拦截 401 token过期等处理
service.interceptors.response.use(
    response =>{
        return response.data
    },
    error =>{
        return Promise.reject(error)
    }
)

export default service