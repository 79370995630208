import request from '@/utils/request'

// 获取用户信息
export function getInfo(data){
    return request({url:'http://8.134.109.162/end/public/index.php/v1/mobile/index',method: 'POST',data})
}

// 获取签名
export function getSign(data){
    return request({url:'http://8.134.109.162/end/public/index.php/v1/jssdk/getSignPackage',method: 'POST',data})
}